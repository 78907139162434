// Class define a set of properties used for worklist filters for queries.
//
class WorklistFilter {
    constructor(worklistName) {
        const today = new Date()
        this.default = false
        this.accessionNumber = ''
        this.diagnostic = []
        this.groups = [],
        this.institution = ''
        this.modalities = []
        this.patientId = ''
        this.patientName = ''
        this.referringPhysicianName = ''
        this.reportedBy = ''
        this.reportStatus = []
        this.studyDateFilter = 'today'
        this.studyDateFrom = today.toISOString().split('T')[0]
        this.studyDateTo = this.studyDateFrom
        this.studyId = ''
        this.worklistName = worklistName
    }

    // Copy property values from a settings object that may not have the most 
    // current set of properties.
    //
    copy(worklistFilter) {
        const propNames = Object.keys(this)
        for(var i=0; i < propNames.length; i++) {
            const propName = propNames[i]
            try {
                if (typeof(worklistFilter[propName]) === 'undefined') {
                    // ignore
                }
                else if (Array.isArray(worklistFilter[propName])) {
                    this[propName] = worklistFilter[propName].slice()
                }
                else if (typeof(worklistFilter[propName]) === 'object') {
                    Object.assign(this[propName], worklistFilter[propName])
                }
                else {
                    this[propName] = worklistFilter[propName]
                }
            }
            catch(e) {
                // ignore missing property
            }
        }
    }
}

module.exports = WorklistFilter;