// main.js - initialize application
//
// Bootstrap Vue
//
import Vue from 'vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons }  from 'bootstrap-vue'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Logging
//
import VueLogger from 'vuejs-logger'

// Authentication
//
import Keycloak from 'keycloak-js'

// Vuex
//
import store from './store'

// Routes for Vue Router
//
import routes from './routes'

// PACS app
//
import App from './App.vue'
import permissions from './common/permissions'
import webServices from './common/webServices'
import worklistActions from './common/worklistActions.json'

Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Landing page configs
//
const LANDING_PAGE_URL = "/landing/index.html"

// Set up logging
//
const isProduction = process.env.NODE_ENV === 'production';
const options = {
  isEnabled: true,
  logLevel : isProduction ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};
Vue.use(VueLogger, options);

function displayLoginError() {
  document.getElementById("app").innerHTML += '<p style="color: white;">Error connecting to server.</p><p style="color: white;"><a href="javascript:window.location.reload()">RETRY</a></p>'
}

function keycloakInit(configs, keycloakInitOptions) {
  if (configs.authUrl == '') {
    Vue.$log.warn("Authentication not configured");
    displayLoginError()
    return
  }

  var keycloakOptions = {
    "url": configs.authUrl,
    "realm": configs.authRealm,
    "clientId": configs.authClientId,
    "checkLoginIframe": true
  }
  let keycloak = new Keycloak(keycloakOptions);
  
  Vue.$log.debug('keycloak init starting')
  keycloak.init(keycloakInitOptions)
  .then((auth) => {
    Vue.$log.debug('keycloak init done')
    if ((keycloakInitOptions.token === undefined) && !auth) {
      window.location.reload();
      window.location = LANDING_PAGE_URL;
    } else {
      Vue.$log.info(`Authenticated=${auth}`);
      store.commit("changeKeycloak", keycloak)
    }
    Vue.$log.debug(keycloak.tokenParsed)
    if (keycloak.tokenParsed) {
      var profile = { 
        username: keycloak.tokenParsed.preferred_username,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name
      }
      store.commit("changeUserProfile", profile);

      // Need to get user's global settings for default_customer_id.
      //
      webServices.readUserGlobalSettings()
      .then(settings => {
        Vue.$log.debug("Loaded user's global settings.")
        Vue.$log.debug(settings)
        store.commit("changeUserGlobalSettings", settings)
        if (settings.default_customer_id && (settings.default_customer_id !== "")) {
          store.commit("changeCustomerId", settings.default_customer_id)
        }

        // Override default customer identifier if provided in URL.
        //
        let hrefParts = window.location.href.split('?')
        if (hrefParts.length > 1) {
          let params = hrefParts[1].split('&')
          if (params.length > 0) {
            for(var i = 0; i < params.length; i++) {
              let paramParts = params[i].split('=')
              if (paramParts.length == 2) {
                Vue.$log.debug(`Handling param=[${paramParts[0]}] value=[${paramParts[1]}]`)
                switch (paramParts[0].toLowerCase()) {
                  case 'cid': {
                    store.commit('changeCustomerId', paramParts[1])
                    break
                  }
                  default:
                    break
                }
              }
            }
          }
        }

        axios.get('/UserSettings/customers', {
          baseURL: store.state.webServicesBaseUrl,
          headers: {
              Authorization: 'Bearer '+keycloak.token
          }
        })
        .then(response => {
          Vue.$log.debug("Loaded user's customers.")
          Vue.$log.debug(response.data)
          store.commit("changeCustomers", response.data)

          // Set up router
          //
          const router = new VueRouter({routes})
      
          // Launch app
          //
          Vue.$log.debug("Starting app...")
          new Vue({
            el: '#app',
            router,
            store,
            render: h => h(App)
          })
        })
        .catch(err => {
          Vue.$log.error(`Unable to read user's customers: ${err.message}`)
          displayLoginError()
        })
        .finally(() => {
          axios.get('/UserSettings/permissions', {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+keycloak.token
            }
          })
          .then(response => {
            Vue.$log.debug("Loaded user's permissions.")
            Vue.$log.debug(response.data)
            store.commit("changePermissions", response.data)

            // Need permissions before getting worklistActions and worklistAltViewers.
            //
            Vue.$log.debug("Querying server for worklistActions")
            webServices.readUserSetting("worklistActions")
            .then(response => {
              if ((response != null) && (Array.isArray(response))) {
                Vue.$log.debug("Found worklistActions")
                // Sanity check - make sure only allowed actions in worklistActions
                var allowedActions = []
                response.forEach(selectedAction => {
                  let canUseAction = false
                  const action = worklistActions.data.find(o => o.key === selectedAction)
                  if (action) {
                    action.permissions.forEach(permission => {
                      if (permissions.groupsForPermission(permission).length > 0) {
                        canUseAction = true
                      }
                    })
                  }
                  if (canUseAction) {
                    allowedActions.push(selectedAction)
                  }
                })
                Vue.$log.debug(allowedActions)
                store.commit("changeWorklistActions", allowedActions)
              }
              else {
                Vue.$log.debug("Cached worklistActions empty, using default")
              }
            })
            .catch(err => {
              Vue.$log.error("Error fetching cached settings: "+err)
            })

            // Sanity check of can view images permission before getting alt viewer settings,
            // not allowed to use these actions without permission.
            //
            Vue.$log.debug("Querying server for worklistAltViewers")
            if (permissions.groupsForPermission(permissions.CAN_VIEW_IMAGES).length > 0) {
              webServices.readUserSetting("worklistAltViewers")
              .then(response => {
                if ((response != null) && (Array.isArray(response))) {
                  Vue.$log.debug("Found worklistAltViewers")
                  store.commit("changeWorklistAltViewers", response)
                }
                else {
                  Vue.$log.warn("worklistAltViewers is null or not an array.")
                }
              })
              .catch(err => {
                Vue.$log.error("Error fetching cached settings: "+err)
              })
            }
            else {
              Vue.$log.debug("No permission to view images, leaving worklistAltViewers empty.")
            }
          })
          .catch(err => {
            Vue.$log.error(`Unable to read user's permissions: ${err.message}`)
          })

          // Create the Tenant to AE title LUT
          //
          var groupAetLut = {}
          for (var g=0; g < keycloak.tokenParsed.groups.length; g++) {
            const group = keycloak.tokenParsed.groups[g]
            Vue.$log.info("Reading AE title for group=["+group+"]")
            webServices.readGroupAeTitle(group)
            .then(aet => {
              groupAetLut[group] = aet;
              store.commit("changeGroupAetLut", groupAetLut);
            })
            .catch(err => {
                Vue.$log.error("Error reading AE title for group=["+group+"]: "+err.message)
            })
          }
        })
      })
      .catch(gsErr => {
        Vue.$log.error(`Unable to read user's global settings: ${gsErr.message}`)
        displayLoginError()
      })
    }

    // Token Refresh
    //
    // +TODO+ need to not refresh if client should be auto-logged out.
    //
    setInterval(() => {
      keycloak.updateToken(70)
      .then((refreshed) => {
        Vue.$log.info('Token refreshed: ' + refreshed);
        if (refreshed) {
          // Refresh the Tenant to AE title LUT
          //
          var groupAetLut = {}
          for (var g=0; g < keycloak.tokenParsed.groups.length; g++) {
            const group = keycloak.tokenParsed.groups[g]
            Vue.$log.info("Reading AE title for group=["+group+"]")
            webServices.readGroupAeTitle(group)
            .then(aet => {
              groupAetLut[group] = aet;
              store.commit("changeGroupAetLut", groupAetLut);
            })
            .catch(err => {
                Vue.$log.error("Error reading AE title for group=["+group+"]: "+err.message)
            })
          }
        }
      })
      .catch((refreshErr) => {
        // Handle session timeout or other token errors
        //
        Vue.$log.error(`Error during token refresh: ${refreshErr.message}`);
      });
    }, 6000)
  })
  .catch(() => {
    Vue.$log.error("Authentication Failed");
    displayLoginError()
  });
}

// Main code
//
axios.get(process.env.BASE_URL+"configs.json")
.then(response => {
  var configs = response.data
  Vue.$log.info("Loaded configs.json");
  Vue.prototype.$configs = configs

  store.commit("changeDocServicesUrl", configs.docServicesUrl);
  store.commit("changeDicomWebQidoUrl", configs.dicomWebQidoUrl);
  store.commit("changeWebServicesBaseUrl", configs.webServicesBaseUrl);
  store.commit("changeUploadLimitMb", configs.uploadLimitMb)
  store.commit("changeQueryLimit", configs.queryLimit)

  // Handle authentication steps.
  //
  var onLoad = "login-required"
  if (configs.useLandingPage && (window.location.search != "?login")) {
    onLoad = "check-sso"
  }
  var keycloakInitOptions = { 
    "onLoad": onLoad
  }
  keycloakInit(configs, keycloakInitOptions)
})
.catch((error) => {
  Vue.$log.error("Error getting configs.json: "+error.message);
  displayLoginError()
});